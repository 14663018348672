import React from "react";

import classes from "./WordList.module.css";

import { client, Message } from "../../App";

import questionIcon from '../../assets/QuestionMark.svg'
import cross from '../../assets/Cross.svg'

const WordList = (props) => {
  const [help, setHelp] = React.useState(false);

  const selectMessage = (event) => {
    client.send(
      JSON.stringify(
        new Message(
          "selectMessage",
          event.target.id
        )
      )
    );
  };

  React.useEffect(() => {
    setTimeout(() => {
      if(localStorage.getItem("endTutorial") === "true"){
        setHelp(true);
      }
    }, 3000)
  }, [])

  return (
    <div className={classes.card}>
      <section className={classes.content}>
        <div style={{width:"auto"}} className={props.tutorialStep === 3 ? classes.tutorialWrapper : ""}>
        {help && <div className={classes.helpContainer}>
          <div><p>Do you have the <strong>same</strong> association?</p> <img onClick={() => {setHelp(!help)}} src={cross}/></div>
          <ul>
            <li>Hover over and click to <strike>cross it</strike> from <br/> your list if <strong>YES</strong></li>
            <li>Discuss it with other if in <strong>DOUBT</strong></li>
          </ul>
        </div>}
          <div className={classes.title}>
            <p>Your Associations</p>
            <img style={{cursor: "pointer"}} src={questionIcon} onClick={() => {if(localStorage.getItem("endTutorial") === "true"){setHelp(!help)}}}/>
          </div>
          <ol>
            {props.entries.map((e) => (
              <div id={classes["item"]} key={Math.random() * 999}>
                <li
                  id={e.text}
                  onClick={selectMessage}
                  className={
                    classes[
                      (e.verdict === true && "valid") ||
                        (e.reveal && !e.voting && "striked") ||
                        (e.selected && "striked") ||
                        (e.voting && "voting")
                    ]
                  }
                >
                  <span id={e.text}>{e.text}</span>{" "}
                  <span id={e.text} className={classes.yesText}>
                    x
                    <span id={e.text} className={classes.tooltip}>{e.cancel || e.selected || (e.reveal && !e.voting) ? "Undo" : "Similar"}</span>
                  </span>
                </li>
              </div>
            ))}
          </ol>
        </div>
      </section>
    </div>
  );
};

export default WordList;
