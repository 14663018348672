import crown from "../../assets/crown.png";

import classes from "./Crown.module.css";

const Crown = () => {
  return (
    <div className={classes.container}>
      <img alt={"crown_icon"} src={crown} />
      <span className={classes.tooltip}>Room owner</span>
    </div>
  );
};

export default Crown;
