import React from 'react';

import classes from './HomePage.module.css';
import buttons from './Buttons.module.css';

import logo from '../../assets/logo.svg';
import { useParams } from 'react-router-dom';

const RoomNotFound = (props) => {
  let { id } = useParams();
  const [code, setCode] = React.useState(id);

  const onChangeCode = (e) => {
    setCode(e.target.value);
  }

  const joinRoom = () => {
    window.location.href = `/rooms/${code}`;
  }

  return (
    <React.Fragment>
      <img className={classes.image} src={logo} alt="logo"/>
      <h1 className={classes.redTitle}>The Room Doesn't Exist</h1>
      <div className={classes.flexContainer}>
        <p className={classes.smallTitle}>Make sure room name is correct</p>
        <div>
          <div className={classes.complexInput}>
            <input
              placeholder={"Room Name *"}
              className={`${classes.codeInput} ${props.error && classes.errorInput}`}
              type="text"
              defaultValue={id}
              id="Room Name"
              maxLength={"6"}
              onChange={onChangeCode}
            />
            <button className={buttons.button2} style={{backgroundColor:"#0096D6", cursor:"pointer"}} onClick={joinRoom}>Join</button>
          </div>
          <p className={classes.userInputErrorAlert} style={{display: `${props.error ? "block" : "none"}`}}>The Room Doesn't Exist</p>
        </div>
        <p className={classes.middleTitle}>or</p>
        <button className={classes.homeButton} onClick={() => {window.location.href = "/"}}>Back to Home</button>
      </div>
    </React.Fragment>
  );
};

export default RoomNotFound;
