import React from "react";
import { client, Message } from "../../App";

import classes from "./HomePage.module.css";
import buttons from "./Buttons.module.css";

import logo from '../../assets/logo.svg';

import Feedback from "../UI/Feedback";
import AvatarEdit from "../UI/AvatarEdit";

const Register = (props) => {
  const [nameError, setNameError] = React.useState("");
  const onChangeName = (e) => {
    localStorage.setItem("username", e.target.value);
    setNameError(false);
  }

  const joinRoom = () => {
    if(!localStorage.getItem("username") || localStorage.getItem("username").length < 2){
      setNameError(true);
      return;
    }
    client.send(JSON.stringify(new Message("joinRoom", {code: props.code, name: localStorage.getItem("username"), avatar: localStorage.getItem("avatar")})));
  };

  return (
    <React.Fragment>
      <img className={classes.image} src={logo} alt="logo"/>
      <h1 className={classes.title}>Join <span style={{color:"#FF9E22"}}>{props.name}'s</span> Room</h1>
      <div className={classes.content} style={{marginTop: "2vw"}}>
        <div style={{margin:0}}>
          <div className={classes.login}>
            <AvatarEdit/>
            <div style={{margin:0, width:"100%"}}>
              <h1>Your Name *</h1>
              <span style={{width:"100%"}}>
                <input
                  onChange={onChangeName}
                  placeholder="John Doe" 
                  type="text" id="Username" 
                  defaultValue={localStorage.getItem("username")} 
                  className={`${classes.userInput} ${nameError && classes.userInputError}`}
                />
                <p className={classes.userInputErrorAlert} style={{display: `${nameError ? "block" : "none"}`}}>Insert a valid username</p>
              </span>
            </div>
          </div>
        </div>
        <div>
          <button className={buttons.button3} style={{backgroundColor: "#0096D6", cursor: "pointer"}} onClick={joinRoom}>Join room</button>
        </div>
      </div>
      <Feedback/>
    </React.Fragment>
  );
};

export default Register;