import React from "react";
import classes from "./AvatarEdit.module.css";
import AvatarsList from "./AvatarsList";

import edit from '../../assets/edit.svg'

const AvatarEdit = () => {
  let avatar = require(`../../assets/avatars/${localStorage.getItem("avatar")}.svg`);
  const [modal, setModal] = React.useState(false);
  const activateList = () => {
    setModal(!modal);
  }

  return (
    <div className={classes.container}>
      <img alt="avatar_icon" className={classes.avatar} src={avatar} />
      <img alt={"edit_icon"} onClick={activateList} className={classes.corner} style={{cursor: "pointer"}} src={edit}/>
      {modal && <AvatarsList onClose={activateList}/>}
    </div>
  );
};

export default AvatarEdit;
