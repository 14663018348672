import Modal from "../../UI/Modal";

const StartModal = (props) => {
  return (
    <Modal onClose={props.onClose}>
      <section>
        <h1>Welcome to Tambo &#129395;</h1>
        <p onClick={props.onClose}>x</p>
      </section>
      <p>
        Let your imagination flow and challenge your friends and colleagues to
        see who can better think outside the box.<br/><br/> What do you see in the picture
        below? A box, a window, a square? Come up with as many associations as
        possible but be creative. Only the unique words get points!<br/><br/> Have some
        fun and Tambo (which means “Play” in Shona)!
      </p>
      <button onClick={props.onClose}>Tambo!</button>
    </Modal>
  );
};

export default StartModal;