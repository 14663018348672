import logo from '../../assets/logo.svg';

import classes from './HomePage.module.css';
import buttons from './Buttons.module.css';

const JoinFailed = (props) => {
  return (
    <div>
      <img className={classes.image} src={logo} alt="logo"/>
      <h1 className={classes.title}>Join <span style={{color:"#FF9E22"}}>{props.name}'s</span> Room</h1>
      <p className={classes.state}>The game has started</p>
      <button className={buttons.button3} style={{width:"80%", backgroundColor: "#0096D6", cursor: "pointer"}} onClick={() => {window.location.href = "/"}}>Back to Home</button>
    </div>
  );
};

export default JoinFailed;