import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../store/data";
import classes from "./TutorialPanel.module.css";

const TutorialPanel = (props) => {
  const dispatch = useDispatch();
  const tutorialStep = useSelector((state) => state.data.tutorialStep);

  return (
    <div id={props.id} style={{width: props.width, top: props.top, bottom: props.bottom, left: props.left, right: props.right}} className={classes.container}>
      <p style={{display:"block"}}>{props.children}</p>
      <div className={classes.footer}>
        <p>{tutorialStep}/{props.totalSteps ? props.totalSteps : 5}</p>
        <div style={{margin:"0"}}>
          {!props.last && <button className={classes.close} onClick={() => dispatch(dataActions.closeTutorial())}>Close</button>}
          <button onClick={() => dispatch(dataActions.prevStep())} className={classes.prev}>Prev</button>
          <button onClick={() => {dispatch(dataActions.nextStep()); if(props.last && !props.end){localStorage.setItem("endTutorial", true)}}} className={classes.next}>{!props.last ? "Next" : "Close"}</button>
        </div>
      </div>
    </div>
  );
};

export default TutorialPanel;
