import React, { Fragment, useState } from "react";
import ReactDOM from "react-dom";

import classes from "./AvatarsList.module.css";

import checkmark from '../../assets/checkmark.png';
import emoji from '../../assets/emoji.png';

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClose} />;
};

const ModalOverlay = (props) => {
  const save = () => {
    localStorage.setItem("avatar", parseInt(props.avatarId));
    props.onClose();
  }

  return (
    <div className={classes.modal}>
      <section>
        <h1>Chose Your Avatar <img alt="disguised_emoji" src={emoji}/></h1>
        <p onClick={props.onClose}>x</p>
      </section>
      <div className={classes.content} style={{overflowY:"hidden"}}>{props.children}</div>
      <button onClick={save}>Save</button>
    </div>
  );
};

const portalElement = document.getElementById("overlays");

const SelectedAvatar = (props) => {
  return <div style={{display:"inline-table"}}>
    <img alt="checkmark" className={classes.checkmark} src={checkmark}/>
    <img alt={`avatar_selected`} src={props.avatar}/>
  </div>
}

const AvatarsList = (props) => {
  let [avatar, setAvatar] = useState(parseInt(localStorage.getItem("avatar")));
  let arr = [];
  for (let i = 1; i <= 100; i++) {
    let image = require(`../../assets/avatars/${i}.svg`);
    arr.push({source: image, id:i});
  }

  const selectAvatar = (e) => {
    setAvatar(parseInt(e.target.id));
  };
  
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay avatarId={avatar} onClose={props.onClose}>
          <div>
            {arr.map((e) => (avatar === e.id ? <SelectedAvatar key={e.id} id={e.id} avatar={e.source}/> : <img onClick={selectAvatar} key={e.id} id={e.id} alt={`avatar_${e.id}`} src={e.source}/>))}
          </div>
        </ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default AvatarsList;
