import { createSlice } from "@reduxjs/toolkit";

const initialDataState = {
  code: null,
  username: null,
  usersName: [],
  admin: null,
  slots: null,
  image: null,
  access: true,
  started: false,
  currState: false,
  highscore: [],
  tableData: [],
  voters: [],
  waiting: [],
  owner: null,
  tutorialStep: parseInt(localStorage.getItem("tutorialStep")),
};

const dataSlice = createSlice({
  name: "data",
  initialState: initialDataState,
  reducers: {
    setCode(state, action) {
      state.code = action.payload;
    },

    setUsername(state, action) {
      state.username = action.payload;
    },

    setUsersName(state, action) {
      state.usersName = action.payload;
    },

    setAdmin(state, action) {
      state.admin = action.payload;
    },

    setSlots(state, action) {
      state.slots = action.payload;
    },

    setImage(state, action) {
      state.image = action.payload;
    },

    setAccess(state, action) {
      state.access = action.payload;
    },

    setStarted(state, action) {
      state.started = action.payload;
    },

    setCurrState(state, action) {
      state.currState = action.payload;
    },

    setHighscore(state, action) {
      state.highscore = action.payload;
    },

    setTableData(state, action) {
      state.tableData = action.payload;
    },

    setVoters(state, action) {
      state.voters = action.payload;
    },

    setWaiting(state, action) {
      state.waiting = action.payload;
    },

    setOwner(state, action) {
      state.owner = action.payload;
    },

    insertVoters(state, action) {
      state.voters.push(action.payload);
    },

    insertNewUser(state, action) {
      state.usersName.push(action.payload);
    },

    resetTutorial(state) {
      if(!localStorage.getItem("endTutorial") || localStorage.getItem("endTutorial") === null){
        state.tutorialStep = 1;
        localStorage.setItem("tutorialStep", state.tutorialStep);
      }
    },

    nextStep(state) {
      state.tutorialStep++;
      localStorage.setItem("tutorialStep", state.tutorialStep);
    },

    prevStep(state) {
      if(state.tutorialStep > 1){
        state.tutorialStep--;
        localStorage.setItem("tutorialStep", state.tutorialStep);
      }
    },

    closeTutorial(state) {
      state.tutorialStep = 6;
      localStorage.setItem("tutorialStep", state.tutorialStep);
      localStorage.setItem("endTutorial", true);
    },

    clear(state) {
      state.code = null;
      state.username = null;
      state.usersName = [];
      state.admin = null;
      state.slots = null;
      state.image = null;
      state.access = true;
      state.started = false;
      state.currState = false;
      state.highscore = false;
      state.tableData = [];
      state.voters = [];
      state.waiting = [];
      state.owner = null;
      state.tutorialStep = parseInt(localStorage.getItem("tutorialStep"));
    },
  },
});

export const dataActions = dataSlice.actions;

export default dataSlice.reducer;
