import React from "react";

import classes from "./Mobile.module.css";

import logo from '../../assets/logo.svg';

import phoneStriked from '../../assets/mobile/PhoneStriked.svg';
import laptop from '../../assets/mobile/Laptop.svg';
import phone from '../../assets/mobile/Phone.svg';
import desktop from '../../assets/mobile/Desktop.svg';
import CompanyLogo from "../UI/CompanyLogo";

const Mobile = () => {
  return (
    <React.Fragment>
      <div className={classes.container}>
        <img className={classes.logo} src={logo} alt="logo" style={{width: "50%"}}/>
        <p className={classes.title1}>Game is<span> not designed </span>to be <br/> played on mobile yet</p>
        <img className={classes.image1} src={phoneStriked} alt="phone_striked_icon"/>
        <p className={classes.title2}>Use your<span> laptop</span>,<span> tablet </span> or <br/><span> computer </span>instead</p>
        <div className={classes.devices}>
          <img src={laptop} alt="laptop_icon"/>
          <img className={classes.centerDevice} src={phone} alt="phone_icon"/>
          <img src={desktop} alt="desktop_icon"/>
        </div>
      </div>
      <CompanyLogo imgWidth={28} fontSize={4}/>
    </React.Fragment>
  );
};

export default Mobile;
