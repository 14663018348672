import classes from "./PlayerCard.module.css";
import Avatar from "../UI/Avatar";

import Crown from "../UI/Crown";

const PlayerCard = (props) => {
  return (
    <div key={Math.random() * 999} style={{borderColor: `${props.voter && props.voter.owner.name === props.name ? "#FF9E22" : "transparent"}`}} className={classes.card}>
      <section className={classes.content}>
        <div className={classes.cardHeader}>
          <Avatar id={props.avatar}/>
          <div className={classes.userBuilder}>
            <h1 className={classes.ownerText}>{props.name}</h1>
            {props.admin === props.name && <Crown/>}
          </div>
          <div className={classes.points}>
            <div className={classes.first}>
              <p className={classes.firstNumber}>{props.score}</p>
              <p className={classes.secondText}>Points</p>
            </div>
            <div className={classes.second}>
              <p className={classes.secondNumber}>{props.messages.length}</p>
              <p className={classes.secondText}>Remaining</p>
            </div>
          </div>
        </div>
        <hr />
        <p className={classes.associations}>Associations:</p>
        <ol>
          {props.entries.map((e) => (
            <li
              key={Math.random() * 999}
              className={
                classes[
                  (e.verdict === true && "valid") ||
                    (e.reveal && !e.voting && "striked") ||
                    (e.selected && "striked") ||
                    (e.voting && "voting")
                ]
              }
            >
              <span className={classes[!e.voting && !e.reveal && !e.cancel && !e.selected && "covered"]}>{e.text}</span>
            </li>
          ))}
        </ol>
      </section>
    </div>
  );
};

export default PlayerCard;
