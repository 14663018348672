import React from "react";

import { useSelector } from "react-redux";
import { client, Message } from "../../App";

import classes from "./Lobby.module.css";
import rotateModes from "../Room/RotateModes.module.css";

import image from "../../assets/tutorial.png";
import copy from "../../assets/copy.svg";
import rotateIcon from '../../assets/rotate.svg'
import verticalIcon from '../../assets/vertical.svg'
import horizontalIcon from '../../assets/horizontal.svg'

import CompanyLogo from "../UI/CompanyLogo.js";
import StartModal from "./Modals/StartModal";
import PlayerList from "./PlayerList";
import TutorialPanel from "../UI/TutorialPanel";

let current = 0;
const Lobby = () => {
  const code = useSelector((state) => state.data.code);
  const admin = useSelector((state) => state.data.admin);
  const owner = useSelector((state) => state.data.owner);
  const usersName = useSelector((state) => state.data.usersName);
  const tutorialStep = useSelector((state) => state.data.tutorialStep);
  const [modalIsShown, setModalIsShown] = React.useState(true);
  const [copyAlert, setCopyAlert] = React.useState(false);
  const [rotate, setRotate] = React.useState(0);
  const [flipX, setFlipX] = React.useState(false);
  const [flipY, setFlipY] = React.useState(false);

  const sendMessage = () => {
    client.send(JSON.stringify(new Message("startGame", code)));
  };

  const deleteRoom = () => {
    client.send(JSON.stringify(new Message("deleteGame", code)));
  };
  
  const leaveRoom = () => {
    client.send(JSON.stringify(new Message("leaveRoom")));
  };

  const hideModalHandler = () => {
    setModalIsShown(false);
    client.send(JSON.stringify(new Message("setOldPlayer")));
  };

  const copyCode = () => {
    navigator.clipboard.writeText(code);
    setCopyAlert(true);
    setTimeout(() =>{setCopyAlert(false)}, 1700)
  }

  const rotateImage = (e) => {
    let angle = [0, 90, 180, 270];
    if(e.target.id !== "flip"){
      current++;
      if (current === 4) {
        current = 0;
      }
      setRotate(angle[current]);
    }
  };

  const flipHorizontally = () => {
    setFlipX(!flipX);
  }

  const flipVertically = () => {
    setFlipY(!flipY);
  }

  let usersList = [];

  for (let i = 0; i < usersName.length; i++) {
    if (usersName[i].name !== admin.name) {
      usersList.push(usersName[i]);
    }
  }
  
  let sanitize = <span>You are the only one in the room. <br/>Invite someone to be able to start the game</span>

  if(usersName.length > 1){
    sanitize = <span>Here you see the list of all players</span> 
  }

  let elements = <button className={classes.closeButton} onClick={leaveRoom}>Leave Room</button>;
  if(admin){
    if (admin.name === localStorage.getItem("username")) {
      elements = (
        <div style={{display:"flex", alignItems:"center"}}>
          <div className={`${classes.startGameDiv} ${usersList.length === 0 && classes.innactiveGameDiv}`}>
            <button className={usersList.length === 0 ? classes.startButtonInnactive : classes.startButton} onClick={sendMessage}>
              Start game
            </button>
            <h3 className={classes.tooltip}>Invite at least one player to start the game</h3>
          </div>
          <button className={classes.closeButton} onClick={deleteRoom}>
            Close room
          </button>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      {tutorialStep <= 5 && <div className={classes.backdrop}/>}
      <div className={classes.title}>
        <section className={tutorialStep === 2 ? classes.tutorialWrapper : ""}>
          {tutorialStep === 2 && <TutorialPanel top={"6vw"} left={"10vw"}>Click here to copy the room name <br/> and share it with your friends</TutorialPanel>}
          <h2>Room Name</h2>
          <div onClick={copyCode}>
            <span>
              <h1>{code}</h1>
              <img src={copy} alt="copyIcon"/>
            </span>
            <p className={classes.copyAlert} style={{display: `${copyAlert === true ? "block" : "none"}`}}>COPIED. NOW SHARE IT!</p>
          </div>
        </section>
        {elements}
      </div>
      <div className={classes.allPanels}>
        {tutorialStep === 1 && <TutorialPanel left={"34vw"} top={"50%"}>{sanitize}</TutorialPanel>}
        {tutorialStep !== 1 ? 
          <PlayerList admin={admin} owner={owner} usersList={usersList} usersName={usersName}/> 
          : 
          <div className={classes.tutorialWrapper}>
            <PlayerList tutorialStep={tutorialStep} admin={admin} owner={owner} usersList={usersList} usersName={usersName}/> 
          </div>}
        <div className={classes.half}>
          <div className={classes.tutorialPanel}>
          {tutorialStep === 3 && <TutorialPanel width={"19vw"} top={"50%"} left={"33.5%"}>
              Look at the picture and imagine what it reminds you. <br/><br/> Rotate it by clicking the buttons at the bottom to change the perspective.
            </TutorialPanel>}
            <section className={classes.tutorialText}>
              <h3>How To Play</h3>
              <div className={classes.centerTutorial}>
                <ul>
                  <li>
                    <strong>Type</strong> any word that <strong>resonates</strong> with the picture and press{" "}
                    <strong> Enter</strong> to submit.
                  </li>
                  <hr/>
                  <li>
                    You have <strong>only 60sec</strong>{" "}
                    to type down as many words as possible
                  </li>
                  <hr/>
                  <li>
                  Press on the buttons at the bottom <strong>to rotate/change</strong> perspective
                  </li>
                </ul>
                <div className={classes.imageDiv}>
                  <div onClick={rotateImage} className={`${classes.imageSection} ${tutorialStep === 3 ? classes.imageTutorialWrapper : ""}`}>
                    <div style={{transform: `rotateX(${flipX ? 180 : 0}deg)`, margin: "auto"}}>
                      <div style={{transform: `rotateY(${flipY ? 180 : 0}deg)`, margin: "auto"}}>
                        <img
                          className={`${rotateModes[`rotate${rotate}deg`]} ${classes.imageRandomized}`}
                          src={image}
                          alt="object"
                          />
                      </div>
                    </div>
                    <div style={{position: "absolute", paddingBottom:".5vw"}}>
                      <img id="flip" alt="verticalFlipIcon" onClick={flipVertically} style={{ width:"1.7vw", height:"1.7vw"}} src={verticalIcon}/>
                      <img alt="rotateIcon" style={{ width:"1.7vw", height:"1.7vw", padding:"0 1vw"}} src={rotateIcon} />
                      <img id="flip" alt="horizontalFlipIcon" onClick={flipHorizontally} style={{ width:"1.7vw", height:"1.7vw"}} src={horizontalIcon}/>
                    </div>
                  </div>
                  <div className={tutorialStep === 4 ? classes.tutorialWrapper : classes.inputDiv}>
                    {tutorialStep === 4 && <TutorialPanel bottom={"5vw"} width={"17vw"}>You have only <span style={{fontWeight:"700"}}>60 seconds</span>. <br/> <span style={{fontWeight:"700"}}>Type</span> as many words that <span style={{fontWeight:"700"}}>resonates</span> <br/> with the picture as you can.<br/> Press <span style={{fontWeight:"700"}}>Enter</span> to submit each.</TutorialPanel>}
                    <input
                      className={classes.input}
                      disabled={true}
                      placeholder="Write associations and press Enter"
                    />
                    <span className={classes.timer}>54sec</span>
                  </div>
                </div>
                <div className={classes.orderedList}>
                  {tutorialStep === 5 && <TutorialPanel last={true} end={true} left={"54%"}>All the associations you've typed in will <br/> appear here</TutorialPanel>}
                  <div className={tutorialStep === 5 ? classes.tutorialWrapper : ""}>
                    <p>Your associations:</p>
                    <ol>
                      <li>Six</li>
                      <li>Flag</li>
                      <li>Pot</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <CompanyLogo class={"logo"}/>
      {owner && (modalIsShown && !owner.oldPlayer) && <StartModal onClose={hideModalHandler}/>}
    </React.Fragment>
  );
};

export default Lobby;
