import Modal from "../../UI/Modal";

const VotingModal = (props) => {
  return (
    <Modal onClose={props.onClose}>
      <section>
        <h1>It's Vote Time! &#128526;</h1>
        <p onClick={props.onClose}>X</p>
      </section>
      <p>
        Now you vote for each association provided by everyone.
        <br />
        <br />
        Press <strong>Yes</strong> for an accurate and unique entry and <strong>No</strong> if the description
        does not match the image.
        <br />
        <br /> If you have the same or a similar association in your list -
        click on <strong>similar</strong> next to your entry to strike it through. This will
        count as "No".
        <br />
        <br /> (All your entries will be prevoted with "Yes". You're welcome 😊)
      </p>
      <button onClick={props.onClose}>Let's vote!</button>
    </Modal>
  );
};

export default VotingModal;
