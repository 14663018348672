import classes from "./Header.module.css";

import logo from "../../assets/logo.svg";
import Feedback from "./Feedback";

const Header = () => {
  const homePage = () => {
    window.location.href = `/`;
  };

  return (
    <header className={classes.container}>
      <img alt="Logo" onClick={homePage} src={logo} />
      <Feedback />
    </header>
  );
};

export default Header;
