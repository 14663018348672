import React from "react";

import classes from "./Vote.module.css";
import Avatar from "../UI/Avatar";

const Vote = (props) => {
  let place = 1;
  let name = props.voteObject.owner.name.split(' ')[0];

  for (let i = 0; i < props.voteObject.owner.entries.length; i++) {
    if (props.voteObject.msg === props.voteObject.owner.entries[i].text) {
      place = i + 1;
    }
  }

  return (
    <div className={`${classes.card} ${props.tutorialStep === 2 ? classes.tutorialWrapper : ""}`}>
      <div className={classes.entry}>
        <Avatar id={props.voteObject.owner.avatar}/>
        <p className={classes.username}>{name}'s</p>
        <p>{`${place}${
          (place === 1 && "st") ||
          (place === 2 && "nd") ||
          (place === 3 && "rd") ||
          (place > 3 && "th")
        } association is`}</p>
      </div>
      <h3>{props.voteObject.msg}</h3>
    </div>
  );
};

export default Vote;
