import React, { useState } from "react";
import { client, Message } from "../../App";

import classes from "./HomePage.module.css";
import buttons from "./Buttons.module.css";

import logo from '../../assets/logo.svg';

import Feedback from "../UI/Feedback";
import AvatarEdit from "../UI/AvatarEdit";

const HomePage = (props) => {
  const [roomName, setRoomName] = useState("");
  const [nameError, setNameError] = useState("");

  const createRoom = () => {
    if (localStorage.getItem("username") && localStorage.getItem("username").length > 2) {
      client.send(JSON.stringify(new Message("createGame", {name: localStorage.getItem("username"), avatar: localStorage.getItem("avatar")})));
      return;
    }
    setNameError(true);
  };

  const onChangeCode = (e) => {
    setRoomName(e.target.value);
    e.target.value = e.target.value.toUpperCase();
    props.setError(false);
  }

  const onChangeName = (e) => {
    localStorage.setItem("username", e.target.value);
    setNameError(false);
  }

  const joinRoom = () => {
    if(!localStorage.getItem("username") || localStorage.getItem("username").length < 2){
      setNameError(true);
      return;
    }
    if(roomName.length > 5){
      client.send(JSON.stringify(new Message("joinRoom", {code: roomName, name: localStorage.getItem("username"), avatar: localStorage.getItem("avatar")})));
    }
  };

  return (
    <React.Fragment>
      <div className={classes.content}>
        <img className={classes.image} src={logo} alt="logo"/>
        <div style={{margin:0}}>
          <div className={classes.login}>
            <AvatarEdit/>
            <div style={{margin:0, width:"100%"}}>
              <h1>Your Name *</h1>
              <span style={{width:"100%"}}>
                <input
                  onChange={onChangeName}
                  placeholder="John Doe" 
                  type="text" id="Username" 
                  defaultValue={localStorage.getItem("username")} 
                  className={`${classes.userInput} ${nameError && classes.userInputError}`}
                />
                <p className={classes.userInputErrorAlert} style={{display: `${nameError ? "block" : "none"}`}}>Insert a valid username</p>
              </span>
            </div>
          </div>
        </div>
        <hr/>
        <button className={buttons.button} onClick={createRoom}>Create Room</button>
        <div className={classes.middle}>
          <p>or</p>
          <h1>Join Room</h1>
        </div>
        <div>
          <div className={classes.complexInput}>
            <input
              onChange={onChangeCode}
              placeholder={"Room Name *"}
              className={`${classes.codeInput} ${props.error && classes.errorInput}`}
              type="text"
              id="Room Name"
              maxLength={"6"}
            />
            <button className={buttons.button2} style={{backgroundColor: `${roomName.length < 6 || props.error ? "#99D5EF" : "#0096D6"}`, cursor: `${roomName.length < 6 || props.error ? "not-allowed" : "pointer"}`}} onClick={joinRoom}>Join</button>
          </div>
          <p className={classes.userInputErrorAlert} style={{display: `${props.error ? "block" : "none"}`}}>The Room Doesn't Exist</p>
        </div>
      </div>
      <Feedback/>
    </React.Fragment>
  );
};

export default HomePage;