import logo2 from "../../assets/logo2.png";

import classes from "./CompanyLogo.module.css";

const CompanyLogo = (props) => {
  return (
    <footer style={props.imgWidth && {marginBottom:`10vw`}} className={classes.container}>
      <a href="https://www.leansherpas.com" style={{width: `${props.imgWidth}%`}} target="_blank" rel="noreferrer">
        <img src={logo2} className={classes.logo} style={{width:`${props.imgWidth && "100%"}`}} alt="Company Logo" />
      </a>
      <p style={{fontSize: `${props.fontSize}vw`, marginRight: `${props.imgWidth && "8vw"}`}}>Game<br/> Provided by</p>
    </footer>
  );
};

export default CompanyLogo;