import React, { useState } from "react";

import HomePage from "./components/Home/HomePage";
import Room from "./components/Room/Room";
import classes from "./App.module.css";

import CompanyLogo from "./components/UI/CompanyLogo";

import { Routes, Route, Navigate } from "react-router-dom";
import ReconnectingWebSocket from "reconnecting-websocket";
import Mobile from "./components/Home/Mobile";

export var client = new ReconnectingWebSocket(
  "wss://tam-bo.herokuapp.com",
  "echo-protocol",
  {
    reconnectInterval: 50,
    minReconnectionDelay: 0,
    reconnectionDelayGrowFactor: 100,
  }
);

// export var client = new ReconnectingWebSocket(
//   "ws://localhost:3001",
//   "echo-protocol",
//   {
//     reconnectInterval: 500,
//     minReconnectionDelay: 0,
//     reconnectionDelayGrowFactor: 100,
//   }
// );

client.onopen = function () {
  console.log("WebSocket Client Connected");
  if (
    localStorage.getItem("token") === null ||
    localStorage.getItem("token").trim() === ""
  ) {
    client.send(JSON.stringify(new Message("generateToken")));
  } else {
    client.send(
      JSON.stringify(new Message("setToken", localStorage.getItem("token")))
    );
  }

  client.onclose = function () {
    console.log("echo-protocol Client Closed");
  };

  client.onerror = function () {
    console.log("Connection Error");
  };

  if (window.location.href.includes("/rooms/")) {
    let code =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];
    client.send(
      JSON.stringify(
        new Message("getData", {
          code: code,
          name: localStorage.getItem("username"),
        })
      )
    );
  }
};

if (
  localStorage.getItem("avatar") === null ||
  isNaN(localStorage.getItem("avatar"))
) {
  localStorage.setItem("avatar", 1);
  console.log("setted avatar to 1 ");
}

if (localStorage.getItem("tutorialStep") === null) {
  localStorage.setItem("tutorialStep", 1);
}

console.log(navigator.userAgent);

var mobile = false;
if (
  navigator.userAgent.match(/Android/i) ||
  navigator.userAgent.match(/webOS/i) ||
  navigator.userAgent.match(/iPhone/i) ||
  navigator.userAgent.match(/iPod/i) ||
  navigator.userAgent.match(/BlackBerry/i) ||
  navigator.userAgent.match(/Windows Phone/i)
) {
  mobile = true;
} else {
  mobile = false;
}

export class Message {
  constructor(type, data) {
    this.type = type;
    this.data = data;
  }
}

const App = () => {
  const [error, setError] = useState(false);

  client.onmessage = (message) => {
    let msg = JSON.parse(message.data);
    if (msg.type !== "getRooms") {
      console.log(msg);
    }
    switch (msg.type) {
      case "token":
        localStorage.setItem("token", msg.data);
        break;
      case "roomInit":
        window.location.href = `/rooms/${msg.data}`;
        break;
      case "joinFailed":
        setError(true);
        break;
      default:
        break;
    }
  };
  
  return (
    <div className={classes.App}>
      {!mobile ? (
        <React.Fragment>
          <Routes>
            <Route path="/" element={<HomePage error={error} setError={setError} />} />
            <Route path="/rooms/:id" element={<Room />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <CompanyLogo />
        </React.Fragment>
      ) : (
        <Mobile />
      )}
    </div>
  );
};

export default App;
