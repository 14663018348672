import React, { useState, useRef } from "react";
import { client, Message } from "../../App";

import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../store/data.js";

import classes from "./Room.module.css";
import rotateModes from "./RotateModes.module.css";

import rotateIcon from '../../assets/rotate.svg'
import verticalIcon from '../../assets/vertical.svg'
import horizontalIcon from '../../assets/horizontal.svg'

import Header from '../UI/Header';
import Lobby from "./Lobby";
import Vote from "./Vote";
import VotersList from "./VotersList";
import PlayerCard from "./PlayerCard";
import WordList from "./WordList";
import VotingModal from "./Modals/VotingModal";
import CustomPlayerCard from "./CustomPlayerCard";
import PlayerList from "./PlayerList";
import TutorialPanel from "../UI/TutorialPanel";
import RoomNotFound from "../Home/RoomNotFound";
import JoinFailed from "../Home/JoinFailed";
import Register from "../Home/Register";

let current = 0;

const Room = () => {
  // let params = useParams();
  const dispatch = useDispatch();
  const ref = useRef("");
  const state = useSelector((state) => state.data.currState);
  const tableData = useSelector((state) => state.data.tableData);
  const code = useSelector((state) => state.data.code);
  const image = useSelector((state) => state.data.image);
  const voters = useSelector((state) => state.data.voters);
  const waiting = useSelector((state) => state.data.waiting);
  const owner = useSelector((state) => state.data.owner);
  const admin = useSelector((state) => state.data.admin);
  const usersName = useSelector((state) => state.data.usersName);
  const highscore = useSelector((state) => state.data.highscore);
  const tutorialStep = useSelector((state) => state.data.tutorialStep);
  const [validRoom, setValidRoom] = useState(0);
  const [joinFailed, setJoinFailed] = useState(false);
  const [register, setRegister] = useState(false);
  const [messages, setMessages] = useState([]);
  const [seconds, setSeconds] = useState(60);
  const [vote, setVote] = useState(null);
  const [playAgain, setPlayAgain] = useState(false);
  const [rotate, setRotate] = useState(0);
  const [flipY, setFlipY] = useState(false);
  const [flipX, setFlipX] = useState(false);
  const [noButton, setNoButton] = useState(false);
  const [yesButton, setYesButton] = useState(false);
  const [lastWord, setLastWord] = useState(false);
  const [modalIsShown, setModalIsShown] = useState(true);

  const hideModalHandler = () => {
    setModalIsShown(false);
  };

  client.onmessage = (message) => {
    let msg = JSON.parse(message.data);
    if (msg.type !== "seconds") {
      console.log(msg);
    }
    switch (msg.type) {
      case "data":
        setValidRoom(1);
        let arr = [];
        msg.data.room.users.forEach((element) => {
          arr.push({ name: element.name, avatar: element.avatar });
        });
        insertData(
          msg.data.room.code,
          arr,
          { name: msg.data.room.admin.name, avatar: msg.data.room.admin.avatar },
          msg.data.room.slots,
          msg.data.room.access,
          msg.data.room.started,
          msg.data.room.level,
          msg.data.user,
          msg.data.room.users,
          msg.data.room.messageVote,
          msg.data.waiting,
          msg.data.voted,
          msg.data.room.voteEnded,
          msg.data.room.highscore
        );
        if (msg.data.room.image) {
          dispatch(dataActions.setImage(msg.data.room.image));
        }
        break;
      case "userJoining":
        dispatch(dataActions.insertNewUser(msg.data));
        break;
      case "invalidCode":
        setValidRoom(-1);
        break;
      case "joinFailed":
        setJoinFailed(true);
        setValidRoom(0);
        setRegister(false);
        dispatch(dataActions.setAdmin(msg.data));
        break;
      case "seconds":
        setSeconds(msg.data);
        break;
      case "gameStarted":
        dispatch(dataActions.setCurrState("Counting"));
        dispatch(dataActions.setImage(msg.data));
        break;
      case "roundEnded":
        dispatch(dataActions.resetTutorial());
        dispatch(dataActions.setOwner(msg.data.user));
        dispatch(dataActions.setTableData(msg.data.list));
        dispatch(dataActions.setCurrState("Voting"));
        break;
      case "startVote":
        setVote(msg.data);
        dispatch(dataActions.setTableData(msg.data.list));
        dispatch(dataActions.setWaiting(msg.data.waiting));
        dispatch(dataActions.setVoters(msg.data.voted));
        break;
      case "allVoted":
        dispatch(dataActions.setVoters([]));
        setYesButton(false);
        setNoButton(false);
        break;
      case "update":
        dispatch(dataActions.setOwner(msg.data));
        break;
      case "activateButtons":
        if (msg.data.blockVote) {
          setYesButton(false);
          setNoButton(true);
        } else if (msg.data.blockVote === false) {
          setYesButton(false);
          setNoButton(false);
        }
        break;
      case "playAgain":
        setPlayAgain(true);
        dispatch(dataActions.setTableData(msg.data.users));
        setMessages([]);
        setLastWord(false);
        break;
      case "voter":
        dispatch(dataActions.setVoters(msg.data.voted));
        dispatch(dataActions.setWaiting(msg.data.waiting));
        break;
      case "confirmDownvote":
        setYesButton(false);
        setNoButton(true);
        break;
      case "confirmUpvote":
        setYesButton(true);
        setNoButton(false);
        break;
      case "confirmSelected":
        dispatch(dataActions.setTableData(msg.data.list));
        dispatch(dataActions.setOwner(msg.data.owner));
        break;
      case "delete":
        window.location.href = `/`;
        break;
      case "lastWord":
        setLastWord(true);
        break;
      case "highscore":
        dispatch(dataActions.setHighscore(msg.data));
        setVote(null);
        break;
      case "register":
        setRegister(true);
        dispatch(dataActions.setAdmin(msg.data.name));
        dispatch(dataActions.setCode(msg.data.code));
        break;
      case "roomInit":
        window.location.reload();
        break;
      default:
        break;
    }
  };

  const insertData = (
    code,
    users,
    admin,
    slots,
    access,
    started,
    state,
    owner,
    usersObject,
    messageVote,
    waiting,
    voted,
    voteEnded,
    highscore
  ) => {
    dispatch(dataActions.setAdmin(admin));
    dispatch(dataActions.setOwner(owner));
    dispatch(dataActions.setCode(code));
    dispatch(dataActions.setUsersName(users));
    dispatch(dataActions.setSlots(slots));
    dispatch(dataActions.setAccess(access));
    dispatch(dataActions.setStarted(started));
    dispatch(dataActions.setCurrState(state));
    dispatch(dataActions.setTableData(usersObject));
    dispatch(dataActions.setHighscore(highscore));
    setMessages(owner.messages);
    setPlayAgain(voteEnded);
    if (messageVote !== null) {
      setVote({ msg: messageVote.text, owner: messageVote.owner });
      if ((messageVote.owner.name === owner.name) && owner.voted !== null) {
        setYesButton(true);
      }
      dispatch(dataActions.setVoters(voted));
      dispatch(dataActions.setWaiting(waiting));
    } else {
      setVote(null);
      dispatch(dataActions.setVoters([]));
      dispatch(dataActions.setWaiting([]));
    }
  };

  const enterKey = (event) => {
    if (event.key === "Enter") {
      if (ref.current?.value.trim() !== "") {
        client.send(
          JSON.stringify(new Message("userMessage", ref.current?.value))
        );
        setMessages([...messages, ref.current?.value]);
        ref.current.value = "";
      }
    }
  };

  const rotateImage = (e) => {
    let angle = [0, 90, 180, 270];
    if(e.target.id !== "flip"){
      current++;
      if (current === 4) {
        current = 0;
      }
      setRotate(angle[current]);
    }
  };

  const flipHorizontally = () => {
    setFlipX(!flipX);
  }

  const flipVertically = () => {
    setFlipY(!flipY);
  }

  const onClickPlayAgain = () => {
    client.send(JSON.stringify(new Message("Restart", code)));
  };

  const onClickNextMessage = () => {
    client.send(JSON.stringify(new Message("nextMsg", code)));
  };

  const onClickUpVote = () => {
    client.send(JSON.stringify(new Message("upvote", vote.msg)));
  };

  const onClickDownVote = () => {
    client.send(JSON.stringify(new Message("downvote", vote.msg)));
  };

  let usersList = [];

  if (owner) {
    for (let i = 0; i < usersName.length; i++) {
      if (usersName[i].name !== admin.name) {
        usersList.push(usersName[i]);
      }
    }
  }

  let timerStyle;

  if(seconds < 21){
    timerStyle = {
      color: "#FFA500",
      backgroundColor: "rgba(230, 195, 11, 0.1)",
      borderColor: "rgba(230, 195, 11, 0.1)"
    }
  }
  
  if(seconds < 6){
    timerStyle = {
      color: "#F46B6B",
      backgroundColor: "rgba(244, 107, 107, 0.1)",
      borderColor: "rgba(244, 107, 107, 0.1)"
    }
  }

  let elements = (
    <React.Fragment>
       <Lobby />
    </React.Fragment>
  );

  //Counting scene
  if (state === "Counting") {
    elements = (
      <div className={classes.entire}>
        <PlayerList owner={owner} admin={admin} usersList={usersList} usersName={usersName} />
        <section className={classes.mainSection}>
          <section className={classes.childSection}>
            <h1 style={{fontSize: "1.8vw"}}>It's Playtime!</h1>
            <section>
              <div>
                <div onClick={rotateImage} className={classes.imageSection}>
                  <div style={{transform: `rotateX(${flipX ? 180 : 0}deg)`, margin: "auto"}}>
                    <div style={{transform: `rotateY(${flipY ? 180 : 0}deg)`, margin: "auto"}}>
                      <img
                        className={`${rotateModes[`rotate${rotate}deg`]} ${classes.imageRandomized}`}
                        src={`data:image/png;base64,${image}`}
                        alt="object"
                        />
                    </div>
                  </div>
                  <div style={{display:"flex", alignItems:"baseline", position: "absolute", paddingBottom:".5vw"}}>
                    <img id="flip" alt="verticalFlipIcon" onClick={flipVertically} style={{transform: `rotateY(${flipY ? 180 : 0}deg)`, width:"1.7vw", height:"1.7vw"}} src={verticalIcon}/>
                    <span style={{transform: `rotateY(${flipY ? 180 : 0}deg)`}}>
                      <img alt="rotateIcon" className={rotateModes[`rotate${rotate}deg${flipX}`]} style={{ width:"1.7vw", height:"1.7vw", padding:"0 1vw"}} src={rotateIcon} />
                    </span>
                    <img id="flip" alt="horizontalFlipIcon" onClick={flipHorizontally} style={{transform: `rotateX(${flipX ? 180 : 0}deg)`, width:"1.7vw", height:"1.7vw"}} src={horizontalIcon}/>
                  </div>
                </div>
                <div className={classes.inputPart}>
                  <input
                    ref={ref}
                    onKeyPress={enterKey}
                    placeholder="Write associations and press Enter"
                    className={classes.input}
                  />
                  <span className={classes.timer} style={timerStyle}>{seconds} sec</span>
                </div>
              </div>
              <section>
                <h1>Your associations:</h1>
                {messages.length === 0 ? (
                  <p style={{fontSize: "1.5vw"}}>
                    There are no <br /> associations yet.
                  </p>
                ) : (
                  <ol>
                    {messages.map((e) => (
                      <li key={Math.random() * 999}>{e}</li>
                    ))}
                  </ol>
                )}
              </section>
            </section>
          </section>
        </section>
      </div>
    );
  }

  // Voting scene
  if (state === "Voting") {
    elements = (
      <React.Fragment>
        {tutorialStep === 3 && <TutorialPanel top={"42.5%"} id={classes["t3"]} totalSteps={admin.name !== localStorage.getItem("username") && 4} >Your words are down here. <br/><br/> During voting, you need to mention if you have the <strong>exact</strong> or a <br/> <strong>similar</strong> association as the one that is up for vote. <br/> If another player insists that it's the same, you need to <strike>strike</strike> <br/> your association by hovering over and clicking it.</TutorialPanel>}
        {admin.name === localStorage.getItem("username") ? tutorialStep <= 5 && <div className={classes.backdrop}/> : tutorialStep <= 4 && <div className={classes.backdrop}/>}
        <div className={classes.panels}>
          <div className={classes.halfPart}>
            {tutorialStep === 1 && <TutorialPanel id={classes["t1"]} totalSteps={admin.name !== localStorage.getItem("username") && 4} left={"40%"}>It's time to vote! <br/> <br/> Here you'll find the list of all the <br/>players and associations they've got. <br/><br/> Words are revealed as you vote.</TutorialPanel>}
            <div className={`${classes.usersCards} ${tutorialStep === 1 ? classes.tutorialWrapper : ""}`}>
              {tableData.map((e) => (
                owner.name === e.name ? 
                  <CustomPlayerCard
                    name={e.name}
                    owner={owner}
                    score={e.points}
                    entries={e.entries}
                    messages={e.messages}
                    avatar={e.avatar}
                    admin={admin.name}
                    key={e.name}
                    voter={vote}
                  />
                  : 
                  <PlayerCard
                    name={e.name}
                    owner={owner}
                    score={e.points}
                    entries={e.entries}
                    messages={e.messages}
                    avatar={e.avatar}
                    admin={admin.name}
                    key={e.name}
                    voter={vote}
                  />
              ))}
            </div>
            <div className={classes.contentHalfPart}>
              <div className={classes.voteCard}>
                <p className={classes.voteSectionTitle}>{playAgain ? "Leader Board" : "It's Vote Time!"}</p>
                <div className={classes.voteSection}>
                  <div onClick={rotateImage} className={classes.imageContainer}>
                    <div style={{transform: `rotateX(${flipX ? 180 : 0}deg)`, margin: "auto"}}>
                      <div style={{transform: `rotateY(${flipY ? 180 : 0}deg)`}}>
                        <img
                          className={rotateModes[`rotate${rotate}deg`]}
                          src={`data:image/png;base64,${image}`}
                          alt="object"
                        />
                      </div>
                    </div>
                    <div style={{display:"flex", alignItems:"baseline", position: "absolute", paddingBottom:".5vw"}}>
                      <img id="flip" alt="verticalFlipIcon" onClick={flipVertically} style={{transform: `rotateY(${flipY ? 180 : 0}deg)`, width:"1.7vw", height:"1.7vw"}} src={verticalIcon}/>
                      <span style={{transform: `rotateY(${flipY ? 180 : 0}deg)`}}>
                        <img alt="rotateIcon" className={rotateModes[`rotate${rotate}deg${flipX}`]} style={{width:"1.7vw", height:"1.7vw", padding:"0 1vw"}} src={rotateIcon}/>
                      </span>
                      <img id="flip" alt="horizontalFlipIcon" onClick={flipHorizontally} style={{transform: `rotateX(${flipX ? 180 : 0}deg)`, width:"1.7vw", height:"1.7vw"}} src={horizontalIcon}/>
                    </div>
                  </div>
                  <div className={classes.leaderboard}>
                    {tutorialStep === 2 && <TutorialPanel id={classes["t2"]} totalSteps={admin.name !== localStorage.getItem("username") && 4} left={"34%"}>You <strong>vote</strong> association by association from each player.</TutorialPanel>}
                    {tutorialStep === 4 && <TutorialPanel id={classes["t4"]} totalSteps={admin.name !== localStorage.getItem("username") && 4} last={admin.name !== localStorage.getItem("username") && true} left={"31.5%"} top={"29.5%"}>
                      Now it's time to give your vote: <br/>
                      <ul>
                        <li>
                          Click <strong>“No”</strong> if that association makes no sense to you <br/> (and mention to the other players why).
                        </li>
                        <li>
                          Otherwise, click <strong>“Yes”</strong>.
                        </li>
                      </ul>
                      <br/>

                      Your associations are automatically voted with “Yes”. <br/><br/> Only one “No” makes association to be rejected.
                    </TutorialPanel>}
                    {vote && (
                      <Vote voteObject={vote} tutorialStep={tutorialStep}/>
                    )}
                    <VotersList
                      admin={admin.name}
                      boolean={playAgain}
                      waiting={waiting}
                      list={voters}
                      players={usersName.length}
                      client={owner}
                      highscore={highscore}
                      voteObject={vote}
                      downVote={onClickDownVote}
                      upVote={onClickUpVote}
                      noBool={noButton}
                      yesBool={yesButton}
                      tutorialStep={tutorialStep}
                    />
                  </div>
                </div>
                {!playAgain ?
                    <div>
                      {tutorialStep === 5 && admin.name === localStorage.getItem("username") && <TutorialPanel id={classes["t5"]} last={true} top={"43%"} right={"27.5%"}>When all the votes are given you can<br/> step to the next player’s word</TutorialPanel>}
                      <div style={{textIndent:`${admin.name !== localStorage.getItem("username") && "-9999px"}`, width: "10vw", margin:"auto", marginTop:"1.3vw"}} className={admin.name === localStorage.getItem("username") && tutorialStep === 5 ? classes.tutorialWrapper : ""}>
                        <button
                          className={
                            waiting.length === 0
                              ? classes.voteButton
                              : classes.voteButtonDisabled
                          }
                          onClick={onClickNextMessage}
                        >
                          {lastWord ? "Results" : "Next Association"}
                        </button>
                      </div>
                      
                    </div>
                    : 
                    <button
                      style={{textIndent:`${admin.name !== localStorage.getItem("username") && "-9999px"}`, backgroundColor: `${admin.name !== localStorage.getItem("username") && "transparent"}`, border:`${admin.name !== localStorage.getItem("username") && "0"}`}}
                      className={admin.name === localStorage.getItem("username") && classes.voteButton}
                      onClick={onClickPlayAgain}
                    >
                      Close game
                    </button>
                      }
              </div>
              <div className={classes.secondHalfPart}>
                {owner &&(
                  <WordList
                    name={owner.name}
                    score={owner.points}
                    entries={owner.entries}
                    messages={owner.messages}
                    color={owner.color}
                    voteOwner={vote}
                    admin={admin.name}
                    tutorialStep={tutorialStep}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {modalIsShown && <VotingModal onClose={hideModalHandler}/>}
      </React.Fragment>
    );
  }
  let failState = <h1>Fetching Room Data...</h1>
  if(validRoom === -1){
    failState = <RoomNotFound/>
  }else if(joinFailed){
    failState = <JoinFailed name={admin}/>
  }

  return (
    <React.Fragment>
      {(validRoom === 1 && !joinFailed) || register ? (
        <React.Fragment>
          {!register && <Header/>}
          {register ? <Register name={admin} code={code}/> : elements}
        </React.Fragment>
      ) : (
        failState
      )}
    </React.Fragment>
  );
};

export default Room;
