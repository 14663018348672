import classes from './Feedback.module.css';

const Feedback = () => {
  return (
    <div className={classes.container}>
      <p>
        Have ideas? <br />
        Love it?
      </p>
      &#128073;
      <a href="mailto:info@leansherpas.com?subject=Tambo&body= ">Send Feedback</a>
    </div>
  );
};

export default Feedback;
