import classes from "./Room.module.css";
import buttonsStyle from './VoteButtons.module.css';
import React from "react";
import Avatar from "../UI/Avatar";

const VotersList = (props) => {
  const maxStack = 5;
  let upVoteList = [];
  let upVoteListCount = 0;
  let downVoteList = [];
  let downVoteListCount = 0;
  let waitingList = [];
  let waitingListCount = 0;
  let rank = 1;
  let remainingVoters = props.players - props.waiting.length;
  let barWidth = 100;
  let cssColorP = "white";

  if(remainingVoters === props.players && props.waiting.length === 0){
    barWidth = 0;
    cssColorP = "#55565A";
  }else{
    let unitValue = 100 / props.players;
    for(let i=0; i<remainingVoters; i++){
      barWidth = barWidth - unitValue;
      cssColorP = "#55565A";
    }
  }

  let cssBar = {
    backgroundSize: `${barWidth}% 100%`
  }

  if(barWidth < 30){
    cssBar = {
      backgroundSize: `${barWidth}% 100%`,
      color: "#55565A"
    }
  }

  for (let i = 0; i < props.list.length; i++) {
    if (props.list[i].voted === "upvote") {
      if (upVoteList.length === maxStack) {
        upVoteList.push({ iterator: 1, avatar: props.list[i].avatar, name: props.list[i].name, position: upVoteList.length+1});
        upVoteListCount++;
      } else if (upVoteList.length > maxStack) {
        upVoteList[upVoteList.length - 1].iterator++;
        if(upVoteList[upVoteList.length - 1].iterator){
          upVoteListCount++;
        }
      } else {
        upVoteList.push({avatar: props.list[i].avatar, name: props.list[i].name, position: upVoteList.length+1});
        upVoteListCount++;
      }
    } else {
      if (downVoteList.length === maxStack) {
        downVoteList.push({ iterator: 1, avatar: props.list[i].avatar, name: props.list[i].name, position: downVoteList.length+1 });
        downVoteListCount++;
      } else if (downVoteList.length > maxStack) {
        try{
          if(downVoteList[downVoteList.length - 1].iterator){
            downVoteList[downVoteList.length - 1].iterator++;
          }
        }catch(error){
          console.log(error);
        }
        downVoteListCount++;
      } else {
        downVoteList.push({avatar: props.list[i].avatar, name: props.list[i].name, position: downVoteList.length+1});
        downVoteListCount++;
      }
    }
  }

  for (let i = 0; i < props.waiting.length; i++) {
    if (waitingList.length === maxStack) {
      waitingList.push({ iterator: 1, avatar: props.waiting[i].avatar, name: props.waiting[i].name, position: waitingList.length+1  });
      waitingListCount++;
    } else if (waitingList.length > maxStack) {
      if(waitingList[waitingList.length - 1].iterator){
        waitingList[waitingList.length - 1].iterator++;
      }
      waitingListCount++;
    } else {
      waitingList.push({avatar: props.waiting[i].avatar, name: props.waiting[i].name, position: waitingList.length+1 });
      waitingListCount++;
    }
  }

  if(waitingListCount < 1){
    cssBar = {
      backgroundSize: `100% 100%`,
    }
  }

  let yesClass = props.noBool ? buttonsStyle.yesList : buttonsStyle.yesVotedList;
  let noClass = props.yesBool ? buttonsStyle.noList : buttonsStyle.noVotedList;

  if(!props.yesBool && !props.noBool) {
    noClass = buttonsStyle.noList;
    yesClass = buttonsStyle.yesList;
  }

  return (
    <div className={`${classes.voters} ${props.tutorialStep === 4 ? classes.tutorialWrapper : ""}`}>
      {!props.boolean && (
        <React.Fragment>
          <p className={classes.tipText}>
            {(props.client && props.voteObject) && (props.client.name === props.voteObject.owner.name) ? "We already voted Yes for you because it's your word" : "Is it an accurate and unique entry that matches the image?"}
          </p>
          <section className={yesClass} onClick={props.upVote}>
            <section>
              <div className={buttonsStyle.box}>
                <span style={{backgroundColor: `transparent`}}/>
              </div>
              <p>Yes</p>
            </section>
            <div>
              <div className={classes.images}>
                {upVoteList.map((e) =>
                  !e.iterator ? 
                  (<div key={Math.random() * 999} className={classes.imageTip}>
                    <div className={classes.imageWrapper}>
                      <span className={classes.circle} style={{zIndex: e.position}}/>
                      <Avatar key={Math.random() * 999} id={e.avatar} position={e.position}/>
                    </div>
                    <span className={classes.tooltip}>{e.name}</span>
                  </div>) : 
                  (<span key={Math.random() * 999} className={classes.blackDot}>
                    +{e.iterator}
                  </span>)
                )}
              </div>
              <p>{upVoteListCount}</p>
            </div>
          </section>
        </React.Fragment>
      )}
      {!props.boolean && (
        <section className={noClass} onClick={props.downVote}>
          <section>
            <div className={buttonsStyle.box}>
              <span style={{backgroundColor: `transparent`}}/>
            </div>
            <p>No</p>
          </section>
          <div className={classes.images}>
            {downVoteList.map((e) =>
              !e.iterator ? 
              (<div key={Math.random() * 999} className={classes.imageTip}>
                <div className={classes.imageWrapper}>
                  <span className={classes.circle} style={{zIndex: e.position}}/>
                  <Avatar key={Math.random() * 999} id={e.avatar} position={e.position}/>
                </div>
                <span className={classes.tooltip}>{e.name}</span>
              </div>) : 
              (<span key={Math.random() * 999} className={classes.blackDot}>
                +{e.iterator}
              </span>)
            )}
            <p>{downVoteListCount}</p>
          </div>
        </section>
      )}
      {!props.boolean && (
        <React.Fragment>
          <hr style={{width:"1.5vw"}}/>
          <section style={cssBar} className={buttonsStyle.waitingList}>
            <p>{waitingListCount > 0 ? 'Pending' : 'Everyone Voted'}</p>
            <div className={classes.images}>
              {waitingList.map((e) =>
                !e.iterator ? 
                (<div key={Math.random() * 999} className={classes.imageTip}>
                  <div className={classes.imageWrapper}>
                    <span className={classes.circle} style={{zIndex: e.position}}/>
                    <Avatar key={Math.random() * 999} id={e.avatar} position={e.position}/>
                  </div>
                  <span className={classes.tooltip}>{e.name}</span>
                </div>) : 
                (<span key={Math.random() * 999} className={classes.blackDot}>
                  +{e.iterator}
                </span>)
              )}
              {waitingListCount > 0 && <p style={{color: cssColorP}}>{waitingListCount}/{props.players}</p>}
            </div>
          </section>
        </React.Fragment>
      )}

      {props.boolean && props.highscore && (
        <div>
          {props.highscore.map(
            (e) =>
              e && (
                <section className={`${classes.sectionPlace} ${classes[`rank${rank > 3 ? "Low" : rank}`]}`} key={Math.random() * 999}>
                  <p className={classes.place}>
                    {`${rank++}`}
                    <sup>
                      {(rank === 2 && "st") ||
                        (rank === 3 && "nd") ||
                        (rank === 4 && "rd") ||
                        (rank > 4 && "th")}
                    </sup>
                  </p>
                  <Avatar id={e.avatar}/>
                  <span className={classes.nameText}>{e.name}</span>
                  <div className={classes.points}>
                    <div className={classes.pointsChild}>
                      <p className={classes.number}>{e.points}</p>
                      <p className={classes.text}>Points</p>
                    </div>
                  </div>
                </section>
              )
          )}
        </div>
      )}
    </div>
  );
};

export default VotersList;