import classes from "./PlayerList.module.css";

import Avatar from "../UI/Avatar";
import Crown from "../UI/Crown";
import React from "react";

const PlayerList = (props) => {
  return (
    <div style={{height: props.tutorialStep === 1 && "100%"}} className={classes.panel}>
      <section className={classes.content}>
        <h3>{`${props.usersName.length} Players`}</h3>
        {props.admin && (
          <div className={classes.user} style={{ alignItems: "stretch" }}>
            <Avatar id={props.admin.avatar} />
            <section className={classes.adminBuilder}>
              <p className={classes.adminName}>{props.admin.name}</p>
              <Crown/>
              {props.owner && props.admin.name === props.owner.name && (
                <p className={classes.adminText}>you</p>
              )}
            </section>
          </div>
        )}
        <hr />
        {props.usersList.length === 0 ? (
          <p className={classes.inviteText}>
            Invite other players to join room
          </p>
        ) : (
          props.usersList.map((e) => (
            <div
              key={e.name}
              className={classes.user}
              style={{marginTop:"1.5vw"}}
            >
              <Avatar id={e.avatar} />
              <section className={classes.adminBuilder}>
                <p className={classes.notAdmin} key={e.name}>
                  {e.name}
                </p>
                {props.owner && props.owner.name === e.name && (
                  <p className={classes.adminText}>you</p>
                )}
              </section>
            </div>
          ))
        )}
      </section>
    </div>
  );
};

export default PlayerList;
